import React from "react"

import Layout from "../../components/layout"
import Band from "../../components/band"
import SEO from "../../components/seo"
import styles from "./contact.module.css"
import sharedStyles from "../../shared/shared.module.css"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" keywords={[`tigerface`, `systems`, `salesforce`]} />

    <Band>
      <h1>Contact</h1>

      <div className={styles.contactText}>
        Send us an email at &nbsp;
        <a className={sharedStyles.tigerfaceLink} href="mailto: contact@tigerfacesystems.com">
          contact@tigerfacesystems.com
        </a>

        {/* 
         This is a contact form in case we need it in the future. 
         For setting up the form with react see::
         https://github.com/jaredpalmer/formik

        <form className={`${grid.mt4}`}>
          <div className={grid.row}>
            <div className={grid.colLg6}>
              <label>Email</label>
              <input type="text" />
            </div>
          </div>
          <div className={`${grid.row} ${grid.mt4}`}>
            <div className={grid.colLg6}>
              <label>First Name</label>
              <input type="text" />
            </div>
            <div className={grid.colLg6}>
              <label>Last Name</label>
              <input type="text" />
            </div>
          </div>
        </form> */}
      </div>
    </Band>
  </Layout>
)

export default ContactPage